@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-button-wrapper {
  display: inline-block;
  position: relative;
  overflow: visible;
}

.custom-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: 2px solid #0f0b0f;
  border-radius: 5px;
  background-color: #f8f548;
  font-family: 'Clash Grotesk', sans-serif;
  font-size: 16px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  position: relative;
  z-index: 1;
}

.custom-button:hover {
  background-color: #e6e64e;
  transform: translate(-5px, -5px);
}
/* for pausing infinte banner on landing screen */
.hover-pause:hover .animate-marquee {
  animation-play-state: paused;
  cursor: all-scroll;
}

.custom-button-wrapper::after {
  content: '';
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background: #000000;
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
  z-index: -1;
  opacity: 0;
}

.custom-button-wrapper:hover::after {
  opacity: 1;
  transform: translate(0, 0);
}

.icon-left {
  margin-right: 10px;
  width: 24px;
}

.icon-right {
  margin-left: 10px;
  width: 24px;
}

.button-text {
  flex-grow: 1;
}

.custom-button:focus {
  outline: none;
}

.main-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.sparkle-left,
.sparkle-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.center-content {
  flex: 1;
  display: flex;
  color: #000;
  flex-direction: row;
  align-items: center;
  gap: 150px;
  justify-content: space-between;
  text-align: center;
}

.cup,
.waveright {
  max-width: 100px;
  position: relative;
  bottom: 50px;
}

@media (max-width: 768px) {
  .main-page-container {
    flex-direction: column;
    align-items: center;
  }

  .sparkle-left,
  .sparkle-right {
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 20px;
  }

  .center-content {
    margin-bottom: 20px;
  }
}

body {
  margin: 0;
  padding: 0;
  background-color: #F9FFDF; /* Light base color */
  background-image:
    url('./assets/background.png'),
    /* Path to your sprinkled texture image */
      radial-gradient(circle at top left, rgba(0, 0, 0, 0.05), transparent),
    /* Top-left shadow */
      radial-gradient(circle at bottom right, rgba(0, 0, 0, 0.1), transparent); /* Bottom-right shadow */
  background-size:
    400px 400px,
    cover,
    cover; /* Texture image size set to 100px by 100px */
  background-position: center, center, center;
  background-repeat: repeat, no-repeat, no-repeat; /* Repeat texture, do not repeat gradients */
  overflow-x: hidden;
}
/* src/styles/app.css */
/* src/styles/fonts.css */
@font-face {
  font-family: 'ClashGrotesk';
  src: url('./fonts/ClashGrotesk-Extralight.otf') format('opentype');
  font-weight: 200; /* Extralight */
  font-style: normal;
}

@font-face {
  font-family: 'ClashGrotesk';
  src: url('./fonts/ClashGrotesk-Light.otf') format('opentype');
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: 'ClashGrotesk';
  src: url('./fonts/ClashGrotesk-Regular.otf') format('opentype');
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: 'ClashGrotesk';
  src: url('./fonts/ClashGrotesk-Medium.otf') format('opentype');
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: 'ClashGrotesk';
  src: url('./fonts/ClashGrotesk-Semibold.otf') format('opentype');
  font-weight: 600; /* Semibold */
  font-style: normal;
}

@font-face {
  font-family: 'ClashGrotesk';
  src: url('./fonts/ClashGrotesk-Bold.otf') format('opentype');
  font-weight: 700; /* Bold */
  font-style: normal;
}
